var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default--text text--darken-1 pb-12"},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("addresses.autoDiscovery.title")))]),_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.description"))+" ")]),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"10","sm":"11"}},[(_vm.googleEnabled)?_c('vue-google-autocomplete',{ref:"address",staticClass:"rounded-xl",attrs:{"id":"map","classname":"service-discovery","placeholder":_vm.$t('addresses.autoDiscovery.placeholder'),"value":_vm.baseAddressString,"enable-geolocation":true,"country":"it"},on:{"placechanged":_vm.getAddressData}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2","sm":"1"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-xl primary white--text",attrs:{"title":"Geolocalizzami","fab":"","color":"primary","icon":"","small":""},on:{"click":_vm.geolocate}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$geolocate")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.buttonTooltip"))+" ")])],1)],1),(_vm.results)?_c('v-card',{staticClass:"results mt-6"},[_c('v-list',[_c('v-subheader',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.driveService"))+" ")])]),(_vm.warehouses.addresses.length == 0)?_c('v-list-item',{staticClass:"mb-8 mb-sm-auto"},[_c('v-list-item-icon',{staticClass:"d-flex align-center service-icon-wrapper"},[_c('img',{staticClass:"service-icon logo-home",attrs:{"src":_vm.$t('navbar.service.1.icon', {
                color: 'grey'
              }),"alt":"Logo Casa"}})]),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.noStoresFound")))])],1):_vm._e(),_vm._l((_vm.warehouses.addresses),function(warehouse){return _c('v-list-item',{key:warehouse.addressId,staticClass:"mr-0 mr-sm-auto",attrs:{"two-line":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"6"}},[_c('v-list-item-icon',{staticClass:"d-flex align-center service-icon-wrapper"},[_c('img',{staticClass:"service-icon",attrs:{"src":_vm.$t('navbar.service.1.icon', { color: 'color' }),"alt":"Logo drive"}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"auto-discovery-title"},[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + warehouse.addressTypeId + ".format", warehouse ))+" ")]),_c('v-list-item-subtitle',{staticClass:"auto-discovery-subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + warehouse.addressTypeId + ".list", warehouse )))])],1)],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"6"}},[_c('v-list-item-action',{staticClass:"ma-0 mr-sm-2"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","min-width":_vm.$vuetify.breakpoint.xs ? 'auto' : 170},on:{"click":function($event){return _vm.handleWarehouseClick(
                    warehouse.addressType,
                    warehouse.addressId
                  )}}},[_c('v-icon',{staticClass:"d-inline d-sm-none"},[_vm._v("mdi-map-marker-outline")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.start"))+" ")])],1)],1)],1)],1)],1)})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }