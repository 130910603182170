<template>
  <div class="default--text text--darken-1 pb-12">
    <h2 class="font-weight-bold">{{ $t("addresses.autoDiscovery.title") }}</h2>
    <div class="text-body-2">
      {{ $t("addresses.autoDiscovery.description") }}
    </div>

    <v-row no-gutters align="center" class="mt-4">
      <v-col cols="10" sm="11">
        <vue-google-autocomplete
          id="map"
          classname="service-discovery"
          class="rounded-xl"
          ref="address"
          :placeholder="$t('addresses.autoDiscovery.placeholder')"
          v-on:placechanged="getAddressData"
          :value="baseAddressString"
          :enable-geolocation="true"
          country="it"
          v-if="googleEnabled"
        >
        </vue-google-autocomplete>
      </v-col>
      <v-col cols="2" sm="1" class="d-flex justify-end">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              title="Geolocalizzami"
              class="rounded-xl primary white--text"
              fab
              color="primary"
              icon
              small
              @click="geolocate"
              v-bind="attrs"
              v-on="on"
              ><v-icon>$geolocate</v-icon></v-btn
            >
          </template>
          {{ $t("addresses.autoDiscovery.buttonTooltip") }}
        </v-tooltip>
      </v-col>
    </v-row>

    <v-card v-if="results" class="results mt-6">
      <v-list>
        <!-- <v-subheader>
          <h3>
            {{ $t("addresses.autoDiscovery.deliveryService") }}
          </h3>
        </v-subheader>
        <v-list-item>
          <v-row no-gutters>
            <v-col cols="12" sm="8" class="d-flex flex-row align-center">
              <v-list-item-icon
                class="d-flex align-center service-icon-wrapper"
              >
                <img
                  class="service-icon logo-home"
                  :src="
                    $t('navbar.service.3.icon', {
                      color: this.dataValidated.active == 1 ? 'color' : 'grey'
                    })
                  "
                  alt="Logo Casa"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="auto-discovery-title"
                  v-if="this.dataValidated.active == 1"
                >
                  {{ $t("addresses.autoDiscovery.addressOk") }}
                </v-list-item-title>
                <v-list-item-title class="auto-discovery-title" v-else>
                  {{ $t("addresses.autoDiscovery.addressKo") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-end align-center">
              <v-list-item-action>
                <v-btn
                  v-if="dataValidated.active == 1"
                  color="primary"
                  depressed
                  min-width="170"
                  @click="handleAddressCreation(dataValidated)"
                >
                  <v-icon class="d-inline d-sm-none"
                    >mdi-map-marker-outline</v-icon
                  >
                  <span>
                    {{ $t("addresses.autoDiscovery.addAddressButton") }}
                  </span>
                </v-btn>
              </v-list-item-action>
            </v-col>
          </v-row>
        </v-list-item>

        <v-divider class="d-flex d-sm-none my-4" /> -->

        <v-subheader>
          <h3>
            {{ $t("addresses.autoDiscovery.driveService") }}
          </h3>
        </v-subheader>
        <v-list-item
          v-if="warehouses.addresses.length == 0"
          class="mb-8 mb-sm-auto"
        >
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon logo-home"
              :src="
                $t('navbar.service.1.icon', {
                  color: 'grey'
                })
              "
              alt="Logo Casa"
            />
          </v-list-item-icon>
          <v-list-item-content>
            {{
              $t("addresses.autoDiscovery.noStoresFound")
            }}</v-list-item-content
          ></v-list-item
        >
        <v-list-item
          two-line
          v-for="warehouse in warehouses.addresses"
          :key="warehouse.addressId"
          class="mr-0 mr-sm-auto"
        >
          <v-row no-gutters>
            <v-col cols="6" class="d-flex flex-row align-center">
              <v-list-item-icon
                class="d-flex align-center service-icon-wrapper"
              >
                <img
                  class="service-icon"
                  :src="$t('navbar.service.1.icon', { color: 'color' })"
                  alt="Logo drive"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="auto-discovery-title">
                  {{
                    $t(
                      "navbar.address." + warehouse.addressTypeId + ".format",
                      warehouse
                    )
                  }}
                </v-list-item-title>
                <v-list-item-subtitle class="auto-discovery-subtitle">
                  {{
                    $t(
                      "navbar.address." + warehouse.addressTypeId + ".list",
                      warehouse
                    )
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-col>

            <v-col cols="6" class="d-flex justify-end align-center">
              <v-list-item-action class="ma-0 mr-sm-2"
                ><v-btn
                  color="primary"
                  depressed
                  :min-width="$vuetify.breakpoint.xs ? 'auto' : 170"
                  @click="
                    handleWarehouseClick(
                      warehouse.addressType,
                      warehouse.addressId
                    )
                  "
                >
                  <v-icon class="d-inline d-sm-none"
                    >mdi-map-marker-outline</v-icon
                  >
                  <span>
                    {{ $t("addresses.autoDiscovery.start") }}
                  </span>
                </v-btn></v-list-item-action
              >
            </v-col>
          </v-row>
        </v-list-item>

        <!-- <v-subheader>
          <h3>
            {{ $t("addresses.autoDiscovery.lockerService") }}
          </h3>
        </v-subheader>
        <v-list-item v-if="lockers.addresses.length == 0">
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon logo-home"
              :src="
                $t('navbar.service.10002.icon', {
                  color: 'grey'
                })
              "
              alt="Logo Casa"
            />
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t("addresses.autoDiscovery.noLockersFound") }}
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          two-line
          v-for="locker in lockers.addresses"
          :key="locker.addressId"
        >
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon locker-logo"
              :src="$t('navbar.service.10002.icon', { color: 'color' })"
              alt="Logo locker"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="auto-discovery-title">
              {{
                $t("navbar.address." + locker.addressTypeId + ".format", locker)
              }}
            </v-list-item-title>
            <v-list-item-subtitle class="auto-discovery-subtitle">
              {{
                $t("navbar.address." + locker.addressTypeId + ".list", locker)
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action
            ><v-btn
              color="primary"
              depressed
              min-width="170"
              @click="
                handleWarehouseClick(locker.addressType, locker.addressId)
              "
            >
              <v-icon class="d-inline d-sm-none">mdi-map-marker-outline</v-icon>
              <span>
                {{ $t("addresses.autoDiscovery.start") }}
              </span>
            </v-btn></v-list-item-action
          >
        </v-list-item> -->
      </v-list>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
.service-discovery {
  background-color: white;
  border: 1px solid $border-color;
  line-height: 20px;
  padding: 8px;
  width: 100%;
  border-color: rgba(0, 0, 0, 0.42);
  border-radius: 4px;
}
.service-icon-wrapper {
  width: 50px !important;
  margin-right: 10px !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-right: 0 !important;
  }
}
.service-icon {
  height: 24px;
  margin-top: 6px;
  &.logo-home {
    height: 29px;
  }
  &.locker-logo {
    height: 31px;
  }
}
.add-address-icon {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}
.auto-discovery-title,
.auto-discovery-subtitle {
  white-space: normal !important;
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AddressService from "~/service/addressService";
import gmapsInit from "./gmaps";
import login from "~/mixins/login";
import { mapActions } from "vuex";
import GeoService from "~/service/geoService";
import includes from "lodash/includes";

var google;

export default {
  name: "ServiceAutodiscory",
  components: { VueGoogleAutocomplete },
  props: { additionalData: { type: Object, required: false } },
  mixins: [login],
  data() {
    return {
      baseAddressString: null,
      warehouses: { addresses: [] },
      lockers: { addresses: [] },
      dataValidated: {},
      deliveryServiceAvailable: null,
      results: false,
      googleEnabled: false
    };
  },
  computed: {
    address() {
      return this.$t(
        "navbar.address." + this.dataValidated.addressTypeId + ".format",
        this.dataValidated
      );
    },
    getType() {
      return "(cities)";
    }
  },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    async getAddressData(addressData, placeResult) {
      try {
        this.dataValidated = await AddressService.isValidAddress({
          country: 1,
          city: addressData.locality,
          postalcode: addressData.postal_code,
          address1: addressData.route,
          addressNumber: addressData.street_number,
          gpsLongitude: addressData.longitude,
          gpsLatitude: addressData.latitude,
          longitude: addressData.longitude,
          latitude: addressData.latitude,
          province: this.getProvince(placeResult)
        });
        this.deliveryServiceAvailable = true;
      } catch (err) {
        this.deliveryServiceAvailable = false;
      }
      //lookup drive
      try {
        this.warehouses = await AddressService.getAddressByDeliveryService(
          1,
          addressData.latitude,
          addressData.longitude,
          global.config.proximityDefaultDistance
        );
      } catch (err) {
        console.log(err);
      }
      // try {
      //   //lookup locker
      //   this.lockers = await AddressService.getAddressByDeliveryService(
      //     2,
      //     addressData.latitude,
      //     addressData.longitude,
      //     global.config.proximityDefaultDistance
      //   );
      // } catch (err) {
      //   console.log(err);
      // }
      this.results = true;
    },

    getProvince(placeResult) {
      let area = placeResult.address_components.find(p => {
        return includes(p.types, "administrative_area_level_2");
      });
      return area["short_name"];
    },

    async handleAddressCreation(address) {
      if (await this.needLogin("service-autodiscovery")) {
        this.$router.push({
          name: "EditAddresses",
          params: {
            newAddress: true,
            discoveredAddress: address
          }
        });
      }
      this.$emit("submit", false);
    },
    async handleWarehouseClick(addressType, addressId) {
      if (await this.needLogin("service-autodiscovery")) {
        await this.setAddress({
          addressType: addressType,
          addressId: addressId
        });
        if (await this.needTimeslot()) {
          this.$router.push({ name: "Home" });
        }
      }
    },
    geolocate() {
      let _this = this;
      if (navigator.geolocation) {
        document.getElementById("map").value = "";
        document.getElementById("map").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                var address = GeoService.decodeGoogleAddress(results[0]);
                address.latitude = address.latitude();
                address.longitude = address.longitude();
                var addressFormat = GeoService.composeAddress(address);
                document.getElementById("map").value = addressFormat;
                document.getElementById("map").placeholder =
                  "Scrivi il tuo indirizzo";
                _this.getAddressData(address);
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
            // var ll = new google.maps.LatLng(
            //   position.coords.latitude,
            //   position.coords.longitude
            // );
            // vm.$refs.address.updateCoordinates(ll);
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }

      // this.$refs.address.geolocate();
    }
  },
  async mounted() {
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
        this.$nextTick(() => {
          if (this.additionalData) {
            if (this.additionalData.addressString) {
              this.baseAddressString = this.additionalData.addressString;
            }
            if (
              this.additionalData.addressData &&
              this.additionalData.placeResult
            ) {
              this.getAddressData(
                this.additionalData.addressData,
                this.additionalData.placeResult
              );
            }
          }
        });
      }
    } catch (error) {
      throw new Error("Error Loading GMAPS");
    }
  }
};
</script>
